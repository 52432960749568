import { Button, Modal } from "antd";

interface Props {
  open: boolean;
  setOpenModal: (open: boolean) => void;
}

const GameInfoModal: React.FC<Props> = ({ open, setOpenModal }) => {
  return (
    <Modal
      open={open}
      title="How to play..."
      onOk={() => setOpenModal(false)}
      onCancel={() => setOpenModal(false)}
      footer={[
        <Button key="back" onClick={() => setOpenModal(false)}>
          Return
        </Button>,
      ]}
    >
      <p>
        - You need to successfully guess the top 5 highest profile
        actors/actresses in the given film without losing all 3 lives.
      </p>
      <p>- Enter a guess in the input box!</p>
      <p>- If it is correct, it will display in it's position in the top 5.</p>
      <p>- If it is incorrect, you will lose a life.</p>
      <p>- Lose all 3 lives before guessing the top 5, and its game over!</p>
    </Modal>
  );
};

export default GameInfoModal;
