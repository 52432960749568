const actors = [
  "Brad Pitt",
  "Angelina Jolie",
  "Tom Hanks",
  "Meryl Streep",
  "Leonardo DiCaprio",
  "Scarlett Johansson",
  "Johnny Depp",
  "Jennifer Lawrence",
  "Robert Downey Jr.",
  "Chris Evans",
  "Emma Watson",
  "Morgan Freeman",
  "Natalie Portman",
  "Chris Hemsworth",
  "Anne Hathaway",
  "Dwayne Johnson",
  "Julia Roberts",
  "Samuel L. Jackson",
  "Ryan Reynolds",
  "Charlize Theron",
  "Matt Damon",
  "Sandra Bullock",
  "Hugh Jackman",
  "Nicole Kidman",
  "Christian Bale",
  "Amy Adams",
  "George Clooney",
  "Kate Winslet",
  "Mark Ruffalo",
  "Gal Gadot",
  "Ryan Gosling",
  "Margot Robbie",
  "Will Smith",
  "Keira Knightley",
  "Jake Gyllenhaal",
  "Emma Stone",
  "Harrison Ford",
  "Tom Cruise",
  "Helena Bonham Carter",
  "Halle Berry",
  "Liam Neeson",
  "Ben Affleck",
  "Cate Blanchett",
  "Michael Fassbender",
  "Channing Tatum",
  "Michelle Williams",
  "Vin Diesel",
  "Joaquin Phoenix",
  "Saoirse Ronan",
  "Bradley Cooper",
  "Jessica Chastain",
  "Daniel Radcliffe",
  "Kirsten Dunst",
  "Idris Elba",
  "Cameron Diaz",
  "Eddie Redmayne",
  "Emily Blunt",
  "Zoe Saldana",
  "Chris Pratt",
  "Joseph Gordon-Levitt",
  "Eva Green",
  "Tom Hardy",
  "James McAvoy",
  "Mila Kunis",
  "Bill Murray",
  "Orlando Bloom",
  "Gwyneth Paltrow",
  "Ewan McGregor",
  "Helena Bonham Carter",
  "Reese Witherspoon",
  "Jason Statham",
  "Blake Lively",
  "Timothée Chalamet",
  "Zendaya",
  "Josh Brolin",
  "Rachel McAdams",
  "Paul Rudd",
  "Tilda Swinton",
  "Javier Bardem",
  "Rachel Weisz",
  "Andy Serkis",
  "Michael Caine",
  "Ben Kingsley",
  "Taron Egerton",
  "Olivia Colman",
  "Daniel Craig",
  "Ralph Fiennes",
  "Hugh Grant",
  "Salma Hayek",
  "Viggo Mortensen",
  "Daisy Ridley",
  "Adam Driver",
  "Felicity Jones",
  "Forest Whitaker",
  "Mahershala Ali",
  "John Boyega",
  "Kelly Marie Tran",
  "Riz Ahmed",
  "Carrie Fisher",
  "Daisy Ridley",
  "Benicio Del Toro",
  "Oscar Isaac",
  "Laura Dern",
  "Mark Hamill",
  "Lupita Nyong'o",
  "Domhnall Gleeson",
  "Naomi Watts",
  "Kate Beckinsale",
  "Clive Owen",
  "Rosamund Pike",
  "Sienna Miller",
  "Henry Cavill",
  "Amy Schumer",
  "Melissa McCarthy",
  "Gal Gadot",
  "Jason Momoa",
  "David Harbour",
  "Millie Bobby Brown",
  "Winona Ryder",
  "David Harbour",
  "Finn Wolfhard",
  "Gaten Matarazzo",
  "Caleb McLaughlin",
  "Noah Schnapp",
  "Sadie Sink",
  "Natalia Dyer",
  "Charlie Heaton",
  "Cara Buono",
  "Matthew Modine",
  "Paul Reiser",
  "Sean Astin",
  "Maya Hawke",
  "Joe Keery",
  "Dacre Montgomery",
  "Jake Gyllenhaal",
  "Maggie Gyllenhaal",
  "Heath Ledger",
  "Tom Holland",
  "Zendaya",
  "Jacob Batalon",
  "Marisa Tomei",
  "Michael Keaton",
  "Jake Johnson",
  "Hailee Steinfeld",
  "Nicolas Cage",
  "Brian Tyree Henry",
  "John Mulaney",
  "Kimiko Glenn",
  "Mahershala Ali",
  "Lily Tomlin",
  "Chris Pine",
  "Zachary Quinto",
  "Zoe Saldana",
  "Karl Urban",
  "Simon Pegg",
  "John Cho",
  "Anton Yelchin",
  "Bruce Greenwood",
  "Alice Eve",
  "Ben Kingsley",
  "Jared Leto",
  "Margot Robbie",
  "Joel Kinnaman",
  "Viola Davis",
  "Jai Courtney",
  "Jay Hernandez",
  "Adewale Akinnuoye-Agbaje",
  "Karen Fukuhara",
  "Cara Delevingne",
  "Scott Eastwood",
  "Ike Barinholtz",
  "Common",
  "Adam Beach",
  "Rita Ora",
  "Kanye West",
  "Kendall Jenner",
  "Kylie Jenner",
  "Kim Kardashian",
  "Khloé Kardashian",
  "Kourtney Kardashian",
  "Kris Jenner",
  "Caitlyn Jenner",
  "Kanye West",
  "Travis Scott",
  "Kanye West",
  "Kendall Jenner",
  "Kylie Jenner",
  "Kim Kardashian",
  "Khloé Kardashian",
  "Kourtney Kardashian",
  "Kris Jenner",
  "Caitlyn Jenner",
  "Kanye West",
  "Travis Scott",
  "Pete Davidson",
  "Machine Gun Kelly",
  "Megan Fox",
  "Kourtney Kardashian",
  "Addison Rae",
  "Charlie D'Amelio",
  "Dixie D'Amelio",
  "Bryce Hall",
  "Noah Beck",
  "Larray",
  "James Charles",
  "David Dobrik",
  "Jason Nash",
  "Liza Koshy",
  "Zane Hijazi",
  "Heath Hussar",
  "Corinna Kopf",
  "Tana Mongeau",
  "Shane Dawson",
  "Jeffree Star",
  "Logan Paul",
  "Jake Paul",
];

export function getRandomActors() {
  const shuffledActors = actors.sort(() => 0.5 - Math.random());
  return shuffledActors.slice(0, 45);
}

export const topGrossingFilms = [
  {
    name: "Avatar",
    actors: [
      "Sam Worthington",
      "Zoe Saldana",
      "Sigourney Weaver",
      "Stephen Lang",
      "Michelle Rodriguez",
    ],
  },
  {
    name: "Avengers: Endgame",
    actors: [
      "Robert Downey Jr.",
      "Chris Evans",
      "Mark Ruffalo",
      "Chris Hemsworth",
      "Scarlett Johansson",
    ],
  },
  {
    name: "Titanic",
    actors: [
      "Leonardo DiCaprio",
      "Kate Winslet",
      "Billy Zane",
      "Kathy Bates",
      "Frances Fisher",
    ],
  },
  {
    name: "Star Wars: The Force Awakens",
    actors: [
      "Harrison Ford",
      "Mark Hamill",
      "Carrie Fisher",
      "Adam Driver",
      "Daisy Ridley",
    ],
  },
  {
    name: "Jurassic World",
    actors: [
      "Chris Pratt",
      "Bryce Dallas Howard",
      "Vincent D'Onofrio",
      "Ty Simpkins",
      "Nick Robinson",
    ],
  },
  {
    name: "The Lion King",
    actors: [
      "Matthew Broderick",
      "James Earl Jones",
      "Jeremy Irons",
      "Rowan Atkinson",
      "Moira Kelly",
    ],
  },
  {
    name: "The Avengers",
    actors: [
      "Robert Downey Jr.",
      "Chris Evans",
      "Mark Ruffalo",
      "Chris Hemsworth",
      "Scarlett Johansson",
    ],
  },
  {
    name: "Furious 7",
    actors: [
      "Vin Diesel",
      "Paul Walker",
      "Dwayne Johnson",
      "Michelle Rodriguez",
      "Tyrese Gibson",
    ],
  },
  {
    name: "Frozen II",
    actors: [
      "Kristen Bell",
      "Idina Menzel",
      "Josh Gad",
      "Jonathan Groff",
      "Sterling K. Brown",
    ],
  },
  {
    name: "Avengers: Infinity War",
    actors: [
      "Robert Downey Jr.",
      "Chris Hemsworth",
      "Mark Ruffalo",
      "Chris Evans",
      "Scarlett Johansson",
    ],
  },
  {
    name: "Beauty and the Beast",
    actors: [
      "Emma Watson",
      "Dan Stevens",
      "Luke Evans",
      "Kevin Kline",
      "Josh Gad",
    ],
  },
  {
    name: "Incredibles 2",
    actors: [
      "Craig T. Nelson",
      "Holly Hunter",
      "Sarah Vowell",
      "Huck Milner",
      "Samuel L. Jackson",
    ],
  },
  {
    name: "Iron Man 3",
    actors: [
      "Robert Downey Jr.",
      "Gwyneth Paltrow",
      "Don Cheadle",
      "Guy Pearce",
      "Ben Kingsley",
    ],
  },
  {
    name: "Minions",
    actors: [
      "Sandra Bullock",
      "Jon Hamm",
      "Michael Keaton",
      "Allison Janney",
      "Steve Coogan",
    ],
  },
  {
    name: "Captain America: Civil War",
    actors: [
      "Chris Evans",
      "Robert Downey Jr.",
      "Scarlett Johansson",
      "Sebastian Stan",
      "Anthony Mackie",
    ],
  },
  {
    name: "Aquaman",
    actors: [
      "Jason Momoa",
      "Amber Heard",
      "Willem Dafoe",
      "Patrick Wilson",
      "Nicole Kidman",
    ],
  },
  {
    name: "Spider-Man: Far From Home",
    actors: [
      "Tom Holland",
      "Samuel L. Jackson",
      "Jake Gyllenhaal",
      "Marisa Tomei",
      "Jon Favreau",
    ],
  },
  {
    name: "Captain Marvel",
    actors: [
      "Brie Larson",
      "Samuel L. Jackson",
      "Ben Mendelsohn",
      "Jude Law",
      "Annette Bening",
    ],
  },
  {
    name: "Transformers: Dark of the Moon",
    actors: [
      "Shia LaBeouf",
      "Rosie Huntington-Whiteley",
      "Josh Duhamel",
      "John Turturro",
      "Tyrese Gibson",
    ],
  },
  {
    name: "The Lord of the Rings: The Return of the King",
    actors: [
      "Elijah Wood",
      "Viggo Mortensen",
      "Ian McKellen",
      "Orlando Bloom",
      "Liv Tyler",
    ],
  },
  {
    name: "Skyfall",
    actors: [
      "Daniel Craig",
      "Javier Bardem",
      "Ralph Fiennes",
      "Naomie Harris",
      "Judi Dench",
    ],
  },
  {
    name: "The Dark Knight Rises",
    actors: [
      "Christian Bale",
      "Tom Hardy",
      "Anne Hathaway",
      "Joseph Gordon-Levitt",
      "Marion Cotillard",
    ],
  },
  {
    name: "Toy Story 4",
    actors: [
      "Tom Hanks",
      "Tim Allen",
      "Annie Potts",
      "Tony Hale",
      "Keegan-Michael Key",
    ],
  },
  {
    name: "Toy Story 3",
    actors: [
      "Tom Hanks",
      "Tim Allen",
      "Joan Cusack",
      "Ned Beatty",
      "Don Rickles",
    ],
  },
  {
    name: "Pirates of the Caribbean: Dead Man's Chest",
    actors: [
      "Johnny Depp",
      "Orlando Bloom",
      "Keira Knightley",
      "Stellan Skarsgård",
      "Bill Nighy",
    ],
  },
  {
    name: "Rogue One: A Star Wars Story",
    actors: [
      "Felicity Jones",
      "Diego Luna",
      "Alan Tudyk",
      "Donnie Yen",
      "Ben Mendelsohn",
    ],
  },
  {
    name: "Pirates of the Caribbean: On Stranger Tides",
    actors: [
      "Johnny Depp",
      "Penélope Cruz",
      "Ian McShane",
      "Geoffrey Rush",
      "Kevin McNally",
    ],
  },
  {
    name: "Despicable Me 3",
    actors: [
      "Steve Carell",
      "Kristen Wiig",
      "Trey Parker",
      "Miranda Cosgrove",
      "Dana Gaier",
    ],
  },
  {
    name: "Jurassic Park",
    actors: [
      "Sam Neill",
      "Laura Dern",
      "Jeff Goldblum",
      "Richard Attenborough",
      "Bob Peck",
    ],
  },
  {
    name: "Finding Dory",
    actors: [
      "Ellen DeGeneres",
      "Albert Brooks",
      "Ed O'Neill",
      "Kaitlin Olson",
      "Ty Burrell",
    ],
  },
  {
    name: "Star Wars: The Last Jedi",
    actors: [
      "Mark Hamill",
      "Carrie Fisher",
      "Adam Driver",
      "Daisy Ridley",
      "John Boyega",
    ],
  },
  {
    name: "Frozen",
    actors: [
      "Kristen Bell",
      "Idina Menzel",
      "Josh Gad",
      "Jonathan Groff",
      "Santino Fontana",
    ],
  },
  {
    name: "Beauty and the Beast (1991)",
    actors: [
      "Paige O'Hara",
      "Robby Benson",
      "Angela Lansbury",
      "Jerry Orbach",
      "David Ogden Stiers",
    ],
  },
  {
    name: "The Lion King (2019)",
    actors: [
      "Donald Glover",
      "Beyoncé",
      "Seth Rogen",
      "Chiwetel Ejiofor",
      "Billy Eichner",
    ],
  },
  {
    name: "Jumanji: Welcome to the Jungle",
    actors: [
      "Dwayne Johnson",
      "Kevin Hart",
      "Jack Black",
      "Karen Gillan",
      "Nick Jonas",
    ],
  },
  {
    name: "Harry Potter and the Deathly Hallows – Part 2",
    actors: [
      "Daniel Radcliffe",
      "Emma Watson",
      "Rupert Grint",
      "Ralph Fiennes",
      "Michael Gambon",
    ],
  },
  {
    name: "Black Panther",
    actors: [
      "Chadwick Boseman",
      "Michael B. Jordan",
      "Lupita Nyong'o",
      "Danai Gurira",
      "Martin Freeman",
    ],
  },
  {
    name: "Transformers: Age of Extinction",
    actors: [
      "Mark Wahlberg",
      "Stanley Tucci",
      "Kelsey Grammer",
      "Nicola Peltz",
      "Jack Reynor",
    ],
  },
  {
    name: "Pirates of the Caribbean: At World's End",
    actors: [
      "Johnny Depp",
      "Orlando Bloom",
      "Keira Knightley",
      "Geoffrey Rush",
      "Bill Nighy",
    ],
  },
  {
    name: "Alice in Wonderland",
    actors: [
      "Johnny Depp",
      "Mia Wasikowska",
      "Helena Bonham Carter",
      "Anne Hathaway",
      "Crispin Glover",
    ],
  },
  {
    name: "Zootopia",
    actors: [
      "Ginnifer Goodwin",
      "Jason Bateman",
      "Idris Elba",
      "Jenny Slate",
      "Nate Torrence",
    ],
  },
  {
    name: "The Jungle Book",
    actors: [
      "Neel Sethi",
      "Bill Murray",
      "Ben Kingsley",
      "Idris Elba",
      "Lupita Nyong'o",
    ],
  },
  {
    name: "The Hobbit: An Unexpected Journey",
    actors: [
      "Martin Freeman",
      "Ian McKellen",
      "Richard Armitage",
      "Ken Stott",
      "Graham McTavish",
    ],
  },
  {
    name: "The Hobbit: The Desolation of Smaug",
    actors: [
      "Martin Freeman",
      "Ian McKellen",
      "Richard Armitage",
      "Benedict Cumberbatch",
      "Lee Pace",
    ],
  },
  {
    name: "The Hobbit: The Battle of the Five Armies",
    actors: [
      "Martin Freeman",
      "Ian McKellen",
      "Richard Armitage",
      "Orlando Bloom",
      "Evangeline Lilly",
    ],
  },
  {
    name: "Finding Nemo",
    actors: [
      "Albert Brooks",
      "Ellen DeGeneres",
      "Alexander Gould",
      "Willem Dafoe",
      "Brad Garrett",
    ],
  },
  {
    name: "Harry Potter and the Philosopher's Stone",
    actors: [
      "Daniel Radcliffe",
      "Emma Watson",
      "Rupert Grint",
      "Richard Harris",
      "Maggie Smith",
    ],
  },
  {
    name: "Harry Potter and the Chamber of Secrets",
    actors: [
      "Daniel Radcliffe",
      "Emma Watson",
      "Rupert Grint",
      "Kenneth Branagh",
      "Richard Harris",
    ],
  },
  {
    name: "Harry Potter and the Prisoner of Azkaban",
    actors: [
      "Daniel Radcliffe",
      "Emma Watson",
      "Rupert Grint",
      "Gary Oldman",
      "David Thewlis",
    ],
  },
  {
    name: "Harry Potter and the Goblet of Fire",
    actors: [
      "Daniel Radcliffe",
      "Emma Watson",
      "Rupert Grint",
      "Ralph Fiennes",
      "Michael Gambon",
    ],
  },
  {
    name: "Harry Potter and the Order of the Phoenix",
    actors: [
      "Daniel Radcliffe",
      "Emma Watson",
      "Rupert Grint",
      "Helena Bonham Carter",
      "Ralph Fiennes",
    ],
  },
  {
    name: "Harry Potter and the Half-Blood Prince",
    actors: [
      "Daniel Radcliffe",
      "Emma Watson",
      "Rupert Grint",
      "Michael Gambon",
      "Jim Broadbent",
    ],
  },
  {
    name: "Harry Potter and the Deathly Hallows – Part 1",
    actors: [
      "Daniel Radcliffe",
      "Emma Watson",
      "Rupert Grint",
      "Helena Bonham Carter",
      "Ralph Fiennes",
    ],
  },
  {
    name: "Guardians of the Galaxy",
    actors: [
      "Chris Pratt",
      "Zoe Saldana",
      "Dave Bautista",
      "Vin Diesel",
      "Bradley Cooper",
    ],
  },
  {
    name: "Guardians of the Galaxy Vol. 2",
    actors: [
      "Chris Pratt",
      "Zoe Saldana",
      "Dave Bautista",
      "Vin Diesel",
      "Bradley Cooper",
    ],
  },
  {
    name: "Star Wars: Episode I – The Phantom Menace",
    actors: [
      "Liam Neeson",
      "Ewan McGregor",
      "Natalie Portman",
      "Jake Lloyd",
      "Ian McDiarmid",
    ],
  },
  {
    name: "Star Wars: Episode II – Attack of the Clones",
    actors: [
      "Ewan McGregor",
      "Natalie Portman",
      "Hayden Christensen",
      "Christopher Lee",
      "Samuel L. Jackson",
    ],
  },
  {
    name: "Star Wars: Episode III – Revenge of the Sith",
    actors: [
      "Ewan McGregor",
      "Natalie Portman",
      "Hayden Christensen",
      "Ian McDiarmid",
      "Samuel L. Jackson",
    ],
  },
  {
    name: "The Lion King (1994)",
    actors: [
      "Matthew Broderick",
      "James Earl Jones",
      "Jeremy Irons",
      "Nathan Lane",
      "Ernie Sabella",
    ],
  },
  {
    name: "The Incredibles",
    actors: [
      "Craig T. Nelson",
      "Holly Hunter",
      "Samuel L. Jackson",
      "Jason Lee",
      "Dominique Louis",
    ],
  },
  {
    name: "The Incredibles 2",
    actors: [
      "Craig T. Nelson",
      "Holly Hunter",
      "Sarah Vowell",
      "Huck Milner",
      "Samuel L. Jackson",
    ],
  },
  {
    name: "The Dark Knight",
    actors: [
      "Christian Bale",
      "Heath Ledger",
      "Aaron Eckhart",
      "Michael Caine",
      "Maggie Gyllenhaal",
    ],
  },
  {
    name: "Avengers: Age of Ultron",
    actors: [
      "Robert Downey Jr.",
      "Chris Hemsworth",
      "Mark Ruffalo",
      "Chris Evans",
      "Scarlett Johansson",
    ],
  },
  {
    name: "Captain America: The Winter Soldier",
    actors: [
      "Chris Evans",
      "Scarlett Johansson",
      "Sebastian Stan",
      "Anthony Mackie",
      "Cobie Smulders",
    ],
  },
  {
    name: "Doctor Strange",
    actors: [
      "Benedict Cumberbatch",
      "Chiwetel Ejiofor",
      "Rachel McAdams",
      "Benedict Wong",
      "Mads Mikkelsen",
    ],
  },
  {
    name: "Thor: Ragnarok",
    actors: [
      "Chris Hemsworth",
      "Tom Hiddleston",
      "Cate Blanchett",
      "Idris Elba",
      "Jeff Goldblum",
    ],
  },
  {
    name: "Spider-Man: Homecoming",
    actors: [
      "Tom Holland",
      "Michael Keaton",
      "Robert Downey Jr.",
      "Marisa Tomei",
      "Zendaya",
    ],
  },
  {
    name: "Spider-Man: Into the Spider-Verse",
    actors: [
      "Shameik Moore",
      "Jake Johnson",
      "Hailee Steinfeld",
      "Mahershala Ali",
      "Brian Tyree Henry",
    ],
  },
  {
    name: "Iron Man",
    actors: [
      "Robert Downey Jr.",
      "Terrence Howard",
      "Jeff Bridges",
      "Gwyneth Paltrow",
      "Leslie Bibb",
    ],
  },
  {
    name: "Iron Man 2",
    actors: [
      "Robert Downey Jr.",
      "Gwyneth Paltrow",
      "Don Cheadle",
      "Scarlett Johansson",
      "Mickey Rourke",
    ],
  },
  {
    name: "Black Widow",
    actors: [
      "Scarlett Johansson",
      "Florence Pugh",
      "David Harbour",
      "Rachel Weisz",
      "Ray Winstone",
    ],
  },
  {
    name: "Wonder Woman",
    actors: [
      "Gal Gadot",
      "Chris Pine",
      "Robin Wright",
      "Danny Huston",
      "David Thewlis",
    ],
  },
  {
    name: "Wonder Woman 1984",
    actors: [
      "Gal Gadot",
      "Chris Pine",
      "Kristen Wiig",
      "Pedro Pascal",
      "Robin Wright",
    ],
  },
  {
    name: "The Matrix",
    actors: [
      "Keanu Reeves",
      "Laurence Fishburne",
      "Carrie-Anne Moss",
      "Hugo Weaving",
      "Joe Pantoliano",
    ],
  },
  {
    name: "The Matrix Reloaded",
    actors: [
      "Keanu Reeves",
      "Laurence Fishburne",
      "Carrie-Anne Moss",
      "Hugo Weaving",
      "Jada Pinkett Smith",
    ],
  },
  {
    name: "The Matrix Revolutions",
    actors: [
      "Keanu Reeves",
      "Laurence Fishburne",
      "Carrie-Anne Moss",
      "Hugo Weaving",
      "Jada Pinkett Smith",
    ],
  },
  {
    name: "The Lord of the Rings: The Fellowship of the Ring",
    actors: [
      "Elijah Wood",
      "Ian McKellen",
      "Orlando Bloom",
      "Sean Bean",
      "Cate Blanchett",
    ],
  },
  {
    name: "The Lord of the Rings: The Two Towers",
    actors: [
      "Elijah Wood",
      "Ian McKellen",
      "Viggo Mortensen",
      "Orlando Bloom",
      "Sean Astin",
    ],
  },
  {
    name: "The Lord of the Rings: The Return of the King",
    actors: [
      "Elijah Wood",
      "Ian McKellen",
      "Viggo Mortensen",
      "Orlando Bloom",
      "Liv Tyler",
    ],
  },
  {
    name: "Toy Story",
    actors: [
      "Tom Hanks",
      "Tim Allen",
      "Don Rickles",
      "Jim Varney",
      "Wallace Shawn",
    ],
  },
  {
    name: "Toy Story 2",
    actors: [
      "Tom Hanks",
      "Tim Allen",
      "Joan Cusack",
      "Kelsey Grammer",
      "Don Rickles",
    ],
  },
  {
    name: "The Simpsons Movie",
    actors: [
      "Dan Castellaneta",
      "Julie Kavner",
      "Nancy Cartwright",
      "Yeardley Smith",
      "Hank Azaria",
    ],
  },
  {
    name: "The Secret Life of Pets",
    actors: [
      "Louis C.K.",
      "Eric Stonestreet",
      "Kevin Hart",
      "Jenny Slate",
      "Ellie Kemper",
    ],
  },
  {
    name: "Shrek",
    actors: [
      "Mike Myers",
      "Eddie Murphy",
      "Cameron Diaz",
      "John Lithgow",
      "Vincent Cassel",
    ],
  },
  {
    name: "Shrek 2",
    actors: [
      "Mike Myers",
      "Eddie Murphy",
      "Cameron Diaz",
      "Julie Andrews",
      "Antonio Banderas",
    ],
  },
  {
    name: "Coco",
    actors: [
      "Anthony Gonzalez",
      "Gael García Bernal",
      "Benjamin Bratt",
      "Alanna Ubach",
      "Renee Victor",
    ],
  },
  {
    name: "Shrek the Third",
    actors: [
      "Mike Myers",
      "Eddie Murphy",
      "Cameron Diaz",
      "Antonio Banderas",
      "Julie Andrews",
    ],
  },
  {
    name: "Harry Potter and the Prisoner of Azkaban",
    actors: [
      "Daniel Radcliffe",
      "Emma Watson",
      "Rupert Grint",
      "Gary Oldman",
      "David Thewlis",
    ],
  },
  {
    name: "Jumanji: The Next Level",
    actors: [
      "Dwayne Johnson",
      "Kevin Hart",
      "Jack Black",
      "Karen Gillan",
      "Awkwafina",
    ],
  },
  {
    name: "Pirates of the Caribbean: Dead Men Tell No Tales",
    actors: [
      "Johnny Depp",
      "Javier Bardem",
      "Geoffrey Rush",
      "Brenton Thwaites",
      "Kaya Scodelario",
    ],
  },
  {
    name: "E.T. the Extra-Terrestrial",
    actors: [
      "Henry Thomas",
      "Drew Barrymore",
      "Peter Coyote",
      "Dee Wallace",
      "Robert MacNaughton",
    ],
  },
  {
    name: "Mission: Impossible - Fallout",
    actors: [
      "Tom Cruise",
      "Henry Cavill",
      "Ving Rhames",
      "Simon Pegg",
      "Rebecca Ferguson",
    ],
  },
  {
    name: "2012",
    actors: [
      "John Cusack",
      "Chiwetel Ejiofor",
      "Amanda Peet",
      "Oliver Platt",
      "Thandiwe Newton",
    ],
  },
  {
    name: "Spider-Man 2",
    actors: [
      "Tobey Maguire",
      "Kirsten Dunst",
      "James Franco",
      "Alfred Molina",
      "Rosemary Harris",
    ],
  },
  {
    name: "Fast & Furious 6",
    actors: [
      "Vin Diesel",
      "Paul Walker",
      "Dwayne Johnson",
      "Michelle Rodriguez",
      "Jordana Brewster",
    ],
  },
  {
    name: "Indiana Jones and the Kingdom of the Crystal Skull",
    actors: [
      "Harrison Ford",
      "Cate Blanchett",
      "Shia LaBeouf",
      "Karen Allen",
      "Ray Winstone",
    ],
  },
  {
    name: "Deadpool 2",
    actors: [
      "Ryan Reynolds",
      "Josh Brolin",
      "Morena Baccarin",
      "Julian Dennison",
      "Zazie Beetz",
    ],
  },
  {
    name: "Deadpool",
    actors: [
      "Ryan Reynolds",
      "Morena Baccarin",
      "T.J. Miller",
      "Ed Skrein",
      "Gina Carano",
    ],
  },
  {
    name: "Star Wars: Episode IV - A New Hope",
    actors: [
      "Mark Hamill",
      "Harrison Ford",
      "Carrie Fisher",
      "Alec Guinness",
      "Peter Cushing",
    ],
  },
  {
    name: "No Time to Die",
    actors: [
      "Daniel Craig",
      "Rami Malek",
      "Léa Seydoux",
      "Lashana Lynch",
      "Ralph Fiennes",
    ],
  },
  {
    name: "Gravity",
    actors: [
      "Sandra Bullock",
      "George Clooney",
      "Ed Harris",
      "Orto Ignatiussen",
      "Phaldut Sharma",
    ],
  },
  {
    name: "The Batman",
    actors: [
      "Robert Pattinson",
      "Zoë Kravitz",
      "Paul Dano",
      "Jeffrey Wright",
      "John Turturro",
    ],
  },
  {
    name: "Thor: Love and Thunder",
    actors: [
      "Chris Hemsworth",
      "Natalie Portman",
      "Christian Bale",
      "Tessa Thompson",
      "Taika Waititi",
    ],
  },
  {
    name: "Fast & Furious Presents: Hobbs & Shaw",
    actors: [
      "Dwayne Johnson",
      "Jason Statham",
      "Idris Elba",
      "Vanessa Kirby",
      "Helen Mirren",
    ],
  },
  {
    name: "The Da Vinci Code",
    actors: [
      "Tom Hanks",
      "Audrey Tautou",
      "Ian McKellen",
      "Jean Reno",
      "Paul Bettany",
    ],
  },
  {
    name: "Maleficent",
    actors: [
      "Angelina Jolie",
      "Elle Fanning",
      "Sharlto Copley",
      "Lesley Manville",
      "Imelda Staunton",
    ],
  },
  {
    name: "The Hunger Games: Mockingjay - Part 1",
    actors: [
      "Jennifer Lawrence",
      "Josh Hutcherson",
      "Liam Hemsworth",
      "Woody Harrelson",
      "Elizabeth Banks",
    ],
  },
  {
    name: "The Amazing Spider-Man",
    actors: [
      "Andrew Garfield",
      "Emma Stone",
      "Rhys Ifans",
      "Denis Leary",
      "Martin Sheen",
    ],
  },
  {
    name: "Shrek Forever After",
    actors: [
      "Mike Myers",
      "Eddie Murphy",
      "Cameron Diaz",
      "Antonio Banderas",
      "Julie Andrews",
    ],
  },
  {
    name: "Suicide Squad",
    actors: [
      "Will Smith",
      "Margot Robbie",
      "Jared Leto",
      "Viola Davis",
      "Joel Kinnaman",
    ],
  },
  {
    name: "Madagascar 3: Europe's Most Wanted",
    actors: [
      "Ben Stiller",
      "Chris Rock",
      "David Schwimmer",
      "Jada Pinkett Smith",
      "Sacha Baron Cohen",
    ],
  },
  {
    name: "X-Men: Days of Future Past",
    actors: [
      "Hugh Jackman",
      "James McAvoy",
      "Michael Fassbender",
      "Jennifer Lawrence",
      "Halle Berry",
    ],
  },
  {
    name: "The Chronicles of Narnia: The Lion, the Witch and the Wardrobe",
    actors: [
      "Georgie Henley",
      "Skandar Keynes",
      "William Moseley",
      "Anna Popplewell",
      "Tilda Swinton",
    ],
  },
  {
    name: "Monsters University",
    actors: [
      "Billy Crystal",
      "John Goodman",
      "Steve Buscemi",
      "Helen Mirren",
      "Peter Sohn",
    ],
  },
  {
    name: "Up",
    actors: [
      "Ed Asner",
      "Christopher Plummer",
      "Jordan Nagai",
      "Bob Peterson",
      "Delroy Lindo",
    ],
  },
  {
    name: "Ne Zha",
    actors: ["Lü Yanting", "Joseph", "Han Mo", "Chen Hao", "Lü Qi"],
  },
  {
    name: "F9: The Fast Saga",
    actors: [
      "Vin Diesel",
      "Michelle Rodriguez",
      "Jordana Brewster",
      "Tyrese Gibson",
      "Ludacris",
    ],
  },
  {
    name: "Captain America: The Winter Soldier",
    actors: [
      "Chris Evans",
      "Scarlett Johansson",
      "Sebastian Stan",
      "Anthony Mackie",
      "Samuel L. Jackson",
    ],
  },
  {
    name: "The Twilight Saga: Breaking Dawn - Part 1",
    actors: [
      "Kristen Stewart",
      "Robert Pattinson",
      "Taylor Lautner",
      "Billy Burke",
      "Peter Facinelli",
    ],
  },
  {
    name: "Dune: Part Two",
    actors: [
      "Timothée Chalamet",
      "Zendaya",
      "Rebecca Ferguson",
      "Josh Brolin",
      "Austin Butler",
    ],
  },
  {
    name: "The Twilight Saga: New Moon",
    actors: [
      "Kristen Stewart",
      "Robert Pattinson",
      "Taylor Lautner",
      "Billy Burke",
      "Ashley Greene",
    ],
  },
  {
    name: "Dawn of the Planet of the Apes",
    actors: [
      "Andy Serkis",
      "Jason Clarke",
      "Gary Oldman",
      "Keri Russell",
      "Toby Kebbell",
    ],
  },
  {
    name: "The Amazing Spider-Man 2",
    actors: [
      "Andrew Garfield",
      "Emma Stone",
      "Jamie Foxx",
      "Dane DeHaan",
      "Colm Feore",
    ],
  },
  {
    name: "Transformers",
    actors: [
      "Shia LaBeouf",
      "Megan Fox",
      "Josh Duhamel",
      "Tyrese Gibson",
      "Rachael Taylor",
    ],
  },
  {
    name: "Interstellar",
    actors: [
      "Matthew McConaughey",
      "Anne Hathaway",
      "Jessica Chastain",
      "Bill Irwin",
      "Ellen Burstyn",
    ],
  },
  {
    name: "Fast X",
    actors: [
      "Vin Diesel",
      "Michelle Rodriguez",
      "Jason Momoa",
      "Jordana Brewster",
      "Tyrese Gibson",
    ],
  },
  {
    name: "It",
    actors: [
      "Bill Skarsgård",
      "Jaeden Martell",
      "Finn Wolfhard",
      "Sophia Lillis",
      "Jack Dylan Grazer",
    ],
  },
  {
    name: "The Wandering Earth",
    actors: [
      "Qu Chuxiao",
      "Li Guangjie",
      "Ng Man-tat",
      "Zhao Jinmai",
      "Wu Jing",
    ],
  },
  {
    name: "The Twilight Saga: Eclipse",
    actors: [
      "Kristen Stewart",
      "Robert Pattinson",
      "Taylor Lautner",
      "Billy Burke",
      "Dakota Fanning",
    ],
  },
  {
    name: "The Hunger Games",
    actors: [
      "Jennifer Lawrence",
      "Josh Hutcherson",
      "Liam Hemsworth",
      "Woody Harrelson",
      "Elizabeth Banks",
    ],
  },
  {
    name: "Mission: Impossible - Ghost Protocol",
    actors: [
      "Tom Cruise",
      "Jeremy Renner",
      "Simon Pegg",
      "Paula Patton",
      "Michael Nyqvist",
    ],
  },
  {
    name: "Mamma Mia!",
    actors: [
      "Meryl Streep",
      "Pierce Brosnan",
      "Colin Firth",
      "Stellan Skarsgård",
      "Amanda Seyfried",
    ],
  },
  {
    name: "Spider-Man: Across the Spider-Verse",
    actors: [
      "Shameik Moore",
      "Hailee Steinfeld",
      "Oscar Isaac",
      "Jake Johnson",
      "Issa Rae",
    ],
  },
  {
    name: "Detective Chinatown 3",
    actors: [
      "Wang Baoqiang",
      "Liu Haoran",
      "Satoshi Tsumabuki",
      "Tony Jaa",
      "Masami Nagasawa",
    ],
  },
  {
    name: "Mission: Impossible - Rogue Nation",
    actors: [
      "Tom Cruise",
      "Jeremy Renner",
      "Simon Pegg",
      "Rebecca Ferguson",
      "Ving Rhames",
    ],
  },
  {
    name: "Forrest Gump",
    actors: [
      "Tom Hanks",
      "Robin Wright",
      "Gary Sinise",
      "Mykelti Williamson",
      "Sally Field",
    ],
  },
  {
    name: "Doctor Strange",
    actors: [
      "Benedict Cumberbatch",
      "Chiwetel Ejiofor",
      "Rachel McAdams",
      "Benedict Wong",
      "Mads Mikkelsen",
    ],
  },
  {
    name: "The Sixth Sense",
    actors: [
      "Bruce Willis",
      "Haley Joel Osment",
      "Toni Collette",
      "Olivia Williams",
      "Donnie Wahlberg",
    ],
  },
  {
    name: "Man of Steel",
    actors: [
      "Henry Cavill",
      "Amy Adams",
      "Michael Shannon",
      "Kevin Costner",
      "Diane Lane",
    ],
  },
  {
    name: "Ice Age: The Meltdown",
    actors: [
      "Ray Romano",
      "John Leguizamo",
      "Denis Leary",
      "Seann William Scott",
      "Queen Latifah",
    ],
  },
  {
    name: "Kung Fu Panda 2",
    actors: [
      "Jack Black",
      "Angelina Jolie",
      "Dustin Hoffman",
      "Gary Oldman",
      "Jackie Chan",
    ],
  },
  {
    name: "The Hunger Games: Mockingjay - Part 2",
    actors: [
      "Jennifer Lawrence",
      "Josh Hutcherson",
      "Liam Hemsworth",
      "Woody Harrelson",
      "Donald Sutherland",
    ],
  },
  {
    name: "Justice League",
    actors: [
      "Ben Affleck",
      "Gal Gadot",
      "Jason Momoa",
      "Ezra Miller",
      "Ray Fisher",
    ],
  },
  {
    name: "Big Hero 6",
    actors: [
      "Ryan Potter",
      "Scott Adsit",
      "Jamie Chung",
      "Damon Wayans Jr.",
      "Genesis Rodriguez",
    ],
  },
  {
    name: "Fantastic Beasts: The Crimes of Grindelwald",
    actors: [
      "Eddie Redmayne",
      "Katherine Waterston",
      "Dan Fogler",
      "Alison Sudol",
      "Ezra Miller",
    ],
  },
  {
    name: "Pirates of the Caribbean: The Curse of the Black Pearl",
    actors: [
      "Johnny Depp",
      "Geoffrey Rush",
      "Orlando Bloom",
      "Keira Knightley",
      "Jack Davenport",
    ],
  },
  {
    name: "Men in Black 3",
    actors: [
      "Will Smith",
      "Tommy Lee Jones",
      "Josh Brolin",
      "Jemaine Clement",
      "Emma Thompson",
    ],
  },
  {
    name: "Star Wars: Episode II - Attack of the Clones",
    actors: [
      "Ewan McGregor",
      "Natalie Portman",
      "Hayden Christensen",
      "Christopher Lee",
      "Samuel L. Jackson",
    ],
  },
  {
    name: "Thor: The Dark World",
    actors: [
      "Chris Hemsworth",
      "Natalie Portman",
      "Tom Hiddleston",
      "Anthony Hopkins",
      "Christopher Eccleston",
    ],
  },
  {
    name: "Moana",
    actors: [
      "Auli'i Cravalho",
      "Dwayne Johnson",
      "Rachel House",
      "Temuera Morrison",
      "Jemaine Clement",
    ],
  },
  {
    name: "Sing",
    actors: [
      "Matthew McConaughey",
      "Reese Witherspoon",
      "Seth MacFarlane",
      "Scarlett Johansson",
      "John C. Reilly",
    ],
  },
  {
    name: "Wonka",
    actors: [
      "Timothée Chalamet",
      "Keegan-Michael Key",
      "Sally Hawkins",
      "Rowan Atkinson",
      "Olivia Colman",
    ],
  },
  {
    name: "Kung Fu Panda",
    actors: [
      "Jack Black",
      "Dustin Hoffman",
      "Angelina Jolie",
      "Ian McShane",
      "Jackie Chan",
    ],
  },
  {
    name: "The Incredibles",
    actors: [
      "Craig T. Nelson",
      "Holly Hunter",
      "Samuel L. Jackson",
      "Jason Lee",
      "Dominique Louis",
    ],
  },
  {
    name: "The Martian",
    actors: [
      "Matt Damon",
      "Jessica Chastain",
      "Kristen Wiig",
      "Jeff Daniels",
      "Michael Peña",
    ],
  },
  {
    name: "Hancock",
    actors: [
      "Will Smith",
      "Charlize Theron",
      "Jason Bateman",
      "Jae Head",
      "Eddie Marsan",
    ],
  },
  {
    name: "Water Gate Bridge",
    actors: ["Wu Jing", "Jackson Yee", "Duan Yihong", "Zhu Yawen", "Li Chen"],
  },
  {
    name: "Fast Five",
    actors: [
      "Vin Diesel",
      "Paul Walker",
      "Dwayne Johnson",
      "Jordana Brewster",
      "Tyrese Gibson",
    ],
  },
  {
    name: "Iron Man 2",
    actors: [
      "Robert Downey Jr.",
      "Gwyneth Paltrow",
      "Don Cheadle",
      "Scarlett Johansson",
      "Mickey Rourke",
    ],
  },
  {
    name: "Ratatouille",
    actors: [
      "Patton Oswalt",
      "Ian Holm",
      "Lou Romano",
      "Brian Dennehy",
      "Peter Sohn",
    ],
  },
  {
    name: "Ant-Man and the Wasp",
    actors: [
      "Paul Rudd",
      "Evangeline Lilly",
      "Michael Peña",
      "Walton Goggins",
      "Bobby Cannavale",
    ],
  },
  {
    name: "How to Train Your Dragon 2",
    actors: [
      "Jay Baruchel",
      "Cate Blanchett",
      "Gerard Butler",
      "Craig Ferguson",
      "America Ferrera",
    ],
  },
  {
    name: "Logan",
    actors: [
      "Hugh Jackman",
      "Patrick Stewart",
      "Dafne Keen",
      "Boyd Holbrook",
      "Stephen Merchant",
    ],
  },
  {
    name: "The Lost World: Jurassic Park",
    actors: [
      "Jeff Goldblum",
      "Julianne Moore",
      "Pete Postlethwaite",
      "Vince Vaughn",
      "Arliss Howard",
    ],
  },
  {
    name: "Casino Royale",
    actors: [
      "Daniel Craig",
      "Eva Green",
      "Mads Mikkelsen",
      "Judi Dench",
      "Jeffrey Wright",
    ],
  },
  {
    name: "The Passion of the Christ",
    actors: [
      "Jim Caviezel",
      "Monica Bellucci",
      "Maia Morgenstern",
      "Christo Jivkov",
      "Francesco De Vito",
    ],
  },
  {
    name: "Life of Pi",
    actors: [
      "Suraj Sharma",
      "Irrfan Khan",
      "Tabu",
      "Adil Hussain",
      "Rafe Spall",
    ],
  },
  {
    name: "Ready Player One",
    actors: [
      "Tye Sheridan",
      "Olivia Cooke",
      "Ben Mendelsohn",
      "Lena Waithe",
      "T.J. Miller",
    ],
  },
  {
    name: "Transformers: The Last Knight",
    actors: [
      "Mark Wahlberg",
      "Anthony Hopkins",
      "Josh Duhamel",
      "Laura Haddock",
      "Santiago Cabrera",
    ],
  },
  {
    name: "Madagascar: Escape 2 Africa",
    actors: [
      "Ben Stiller",
      "Chris Rock",
      "David Schwimmer",
      "Jada Pinkett Smith",
      "Sacha Baron Cohen",
    ],
  },
  {
    name: "War of the Worlds",
    actors: [
      "Tom Cruise",
      "Dakota Fanning",
      "Tim Robbins",
      "Miranda Otto",
      "Justin Chatwin",
    ],
  },
  {
    name: "Tangled",
    actors: [
      "Mandy Moore",
      "Zachary Levi",
      "Donna Murphy",
      "Ron Perlman",
      "M.C. Gainey",
    ],
  },
  {
    name: "Despicable Me 4",
    actors: [
      "Steve Carell",
      "Kristen Wiig",
      "Pierre Coffin",
      "Miranda Cosgrove",
      "Dana Gaier",
    ],
  },
  {
    name: "Quantum of Solace",
    actors: [
      "Daniel Craig",
      "Olga Kurylenko",
      "Mathieu Amalric",
      "Judi Dench",
      "Giancarlo Giannini",
    ],
  },
  {
    name: "Men in Black",
    actors: [
      "Will Smith",
      "Tommy Lee Jones",
      "Linda Fiorentino",
      "Vincent D'Onofrio",
      "Rip Torn",
    ],
  },
  {
    name: "The Croods",
    actors: [
      "Nicolas Cage",
      "Emma Stone",
      "Ryan Reynolds",
      "Catherine Keener",
      "Cloris Leachman",
    ],
  },
  {
    name: "The Hangover Part II",
    actors: [
      "Bradley Cooper",
      "Ed Helms",
      "Zach Galifianakis",
      "Justin Bartha",
      "Ken Jeong",
    ],
  },
  {
    name: "I Am Legend",
    actors: [
      "Will Smith",
      "Alice Braga",
      "Charlie Tahan",
      "Salli Richardson-Whitfield",
      "Willow Smith",
    ],
  },
  {
    name: "Monsters, Inc.",
    actors: [
      "John Goodman",
      "Billy Crystal",
      "Mary Gibbs",
      "Steve Buscemi",
      "James Coburn",
    ],
  },
  {
    name: "Operation Red Sea",
    actors: ["Zhang Yi", "Huang Jingyu", "Hai Qing", "Du Jiang", "Prince Mak"],
  },
  {
    name: "Night at the Museum",
    actors: [
      "Ben Stiller",
      "Carla Gugino",
      "Ricky Gervais",
      "Dick Van Dyke",
      "Mickey Rooney",
    ],
  },
  {
    name: "Fifty Shades of Grey",
    actors: [
      "Dakota Johnson",
      "Jamie Dornan",
      "Jennifer Ehle",
      "Eloise Mumford",
      "Victor Rasuk",
    ],
  },
  {
    name: "The Little Mermaid",
    actors: [
      "Halle Bailey",
      "Jonah Hauer-King",
      "Melissa McCarthy",
      "Javier Bardem",
      "Awkwafina",
    ],
  },
];
