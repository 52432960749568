import dayjs from "dayjs";
import { useState, useEffect } from "react";

function getStorageValue<T>(key: string, defaultValue: T): T {
  const saved = localStorage.getItem(key);

  return saved ? JSON.parse(saved) : defaultValue;
}

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState(() => {
    return getStorageValue<T>(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export const useGameHistory = () => {
  const winnerKeys = Object.keys({ ...localStorage }).filter((key) => {
    const [, , , type] = key.split("_");
    return type === "winner";
  });

  const data = winnerKeys.map((key) => {
    const [, band, date] = key.split("_");
    const winnerKeyValue = localStorage.getItem(key);
    const livesRemaining = localStorage.getItem(
      `filmifive_${band}_${date}_lives`
    );
    const correctGuesses = localStorage.getItem(
      `filmifive_${band}_${date}_correctGuesses`
    );

    return {
      band,
      date,
      won: winnerKeyValue,
      livesRemaining,
      correctGuesses: JSON.parse(correctGuesses ?? "[]").length,
    };
  });

  return data.sort((a, b) => {
    const [aDD, aMM, aYYYY] = a.date.split("/");
    const [bDD, bMM, bYYYY] = b.date.split("/");

    const dateA = new Date(Number(aYYYY), Number(aMM), Number(aDD));
    const dateB = new Date(Number(bYYYY), Number(bMM), Number(bDD));

    return dayjs(dateA).isBefore(dayjs(dateB)) ? 1 : -1;
  });
};
