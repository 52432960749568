import { Button, Modal } from "antd";
import { useGameHistory } from "../utils/useLocalStorage";

interface Props {
  open: boolean;
  setOpenModal: (open: boolean) => void;
}

const GameHistoryModal: React.FC<Props> = ({ open, setOpenModal }) => {
  const gameHistory = useGameHistory();

  return (
    <Modal
      open={open}
      styles={{
        content: {
          padding: 12,
        },
      }}
      title="Filmifive Game History"
      onOk={() => setOpenModal(false)}
      onCancel={() => setOpenModal(false)}
      footer={[
        <Button key="back" onClick={() => setOpenModal(false)}>
          Return
        </Button>,
      ]}
    >
      <div className="overflow-x-auto">
        <table>
          <th className="pr-2 text-left">Band</th>
          <th className="pr-2 text-left">Date</th>
          <th className="pr-2 text-center">Score</th>
          <th className="pr-2 text-center">Lives</th>
          <th className="pr-2 text-center">Won</th>

          <tbody>
            {gameHistory.map(
              ({ band, date, won, livesRemaining, correctGuesses }) => {
                return (
                  <tr key={`$${band}_${date}`}>
                    <td className="pr-2 pb-2  text-left">{band}</td>
                    <td className="pr-2 pb-2 text-left">{date}</td>
                    <td className="pr-2 pb-2 text-center">
                      {correctGuesses}/5
                    </td>
                    <td className="pr-2 pb-2 text-center">
                      {livesRemaining}/3
                    </td>
                    <td className="pr-2 pb-2 text-center">
                      {won === "true" ? "✅" : "❌"}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default GameHistoryModal;
